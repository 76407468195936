import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Form, Input, Button } from 'antd'
import { EnvironmentOutlined, MailOutlined, ShopOutlined } from '@ant-design/icons'
import { notification } from 'shared'
import { SocialItem, UploadImage, QuillEditorAntd } from 'components'
import { store } from 'features/storeSettings/models'

import styles from './StoreSettings.module.scss'
import { SocialNetwork } from 'components/SocialItem/SocialItem'
import StoreLogoModal from '../StoreLogoModal'
import StoreCoverModal from '../StoreCoverModal'

const StoreSettings: React.FC = () => {
  const [form] = Form.useForm()

  const [isStoreLogoModalVisible, setStoreLogoModalVisible] = useState<boolean>(false)
  const [isStoreCoverModalVisible, setStoreCoverModalVisible] = useState<boolean>(false)

  const onFinish = (_values: any) => {
    // const { bannerImageUrl, avatarImageUrl, ...data } = values

    // store.update(data)
    notification({
      text: 'Update Store error!',
      description: "You can't update Store",
      type: 'error'
    })
  }

  const onFinishFailed = (errors: any) => {
    const description = errors.errorFields.map((item: any) => item.errors.join('\n')).join('\n')
    notification({
      text: 'Update Store error!',
      description,
      type: 'error'
    })
  }

  useEffect(() => {
    store.fetch()
  }, [])

  useEffect(() => {
    if (store.status === 'success') {
      const bannerImageUrl = []
      const avatarImageUrl = []

      if (store.bannerImageUrl) {
        bannerImageUrl.push({
          uid: 'bannerImageUrl',
          name: 'BannerImage',
          status: 'done',
          size: 0,
          type: 'image/jpeg',
          url: store.bannerImageUrl
        })
      }
      if (store.avatarImageUrl) {
        avatarImageUrl.push({
          uid: 'avatarImageUrl',
          name: 'AvatarImage',
          status: 'done',
          size: 0,
          type: 'image/jpeg',
          url: store.avatarImageUrl
        })
      }

      form.setFieldsValue({
        name: store.name,
        description: store.description,
        supportEmail: store.supportEmail,

        avatarImageUrl,
        bannerImageUrl,

        // bannerLink: store.bannerLink,
        // isActive: store.isActive,

        // hasCustomSeo: store.hasCustomSeo,
        // seoUrl: store.seoUrl,
        // seoTitle: store.seoTitle,
        // seoDescription: store.seoDescription,
        // seoKeywords: store.seoKeywords,

        facebookLink: store.facebookLink,
        twitterLink: store.twitterLink,
        instagramLink: store.instagramLink,
        linkedInLink: store.linkedInLink,
        pinterestLink: store.pinterestLink,
        tiktokLink: store.tiktokLink,
        youtubeLink: store.youtubeLink,

        location: store.location
      })
    }
  }, [store.status])

  const handleReset = () => {
    form.resetFields()
  }

  return (
    <Form
      name='basic'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      size='large'
      layout='horizontal'
      labelAlign='left'
      form={form}
      initialValues={{
        name: store.name,
        description: store.description,

        bannerLink: store.bannerLink,
        // isActive: store.isActive,

        hasCustomSeo: store.hasCustomSeo,
        // seoUrl: store.seoUrl,
        // seoTitle: store.seoTitle,
        // seoDescription: store.seoDescription,
        // seoKeywords: store.seoKeywords,

        supportEmail: store.supportEmail,

        facebookLink: store.facebookLink,
        twitterLink: store.twitterLink,
        instagramLink: store.instagramLink,
        linkedInLink: store.linkedInLink,
        pinterestLink: store.pinterestLink,
        tiktokLink: store.tiktokLink,
        youtubeLink: store.youtubeLink,

        location: store.location
      }}
      className={styles.container}
      disabled={store.status === 'loading'}
    >
      <Form.Item name='facebookLink' noStyle hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item name='twitterLink' noStyle hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item name='instagramLink' noStyle hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item name='linkedInLink' noStyle hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item name='pinterestLink' noStyle hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item name='tiktokLink' noStyle hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item name='youtubeLink' noStyle hidden>
        <Input disabled />
      </Form.Item>
      <div className={styles.header}>
        <div className={styles.textColumn}>
          <div className={styles.title}>Settings</div>
          <div className={styles.subtitle}>Manage all settings for store here</div>
        </div>
        <div className={styles.btnGroup}>
          <Button size='large' onClick={handleReset} loading={store.status === 'loading'}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={store.status === 'loading'} size='large'>
            Save
          </Button>
        </div>
      </div>
      <div className={styles.form}>
        {/* <div className={styles.isActive}>
            <Typography.Text className={styles.labelForIsActive}>Enabled</Typography.Text>
            <Form.Item className={styles.isActiveFormItem} name='isActive' valuePropName='checked'>
              <Switch />
            </Form.Item>
          </div> */}

        <div className={styles.formItemRow}>
          <div className={styles.label}>
            Logo Image
            <div className={styles.labelSubtitle}>
              Logo will be displayed on your profile{' '}
              <span className={styles.labelLink} onClick={() => setStoreLogoModalVisible(true)}>
                Learn how it works?
              </span>
            </div>
          </div>
          <div className={styles.field}>
            <Form.Item
              name='avatarImageUrl'
              rules={[{ required: false, message: 'Please add store logo!' }]}
              style={{ width: '100%' }}
              valuePropName='storeFileList'
            >
              <UploadImage aspectRatio={105 / 105} shape='round' />
            </Form.Item>
          </div>
        </div>
        <div className={styles.formItemRow}>
          <div className={styles.label}>Store Name</div>
          <Form.Item name='name' rules={[{ required: true, message: 'Please input store name!' }]} style={{ width: '100%' }}>
            <Input
              placeholder='Ex. FanMadeFits'
              size='large'
              style={{ width: '100%' }}
              prefix={<ShopOutlined style={{ fontSize: 20, color: '#667085 ' }} />}
            />
          </Form.Item>
        </div>

        <div className={styles.formItemRow}>
          <div className={styles.label}>Customer service email</div>
          <Form.Item
            name='supportEmail'
            rules={[{ required: true, message: 'Please input customer service email!' }]}
            style={{ width: '100%' }}
          >
            <Input
              placeholder='Ex. support@email.com'
              size='large'
              style={{ width: '100%' }}
              prefix={<MailOutlined style={{ fontSize: 20, color: '#667085 ' }} />}
            />
          </Form.Item>
        </div>
        <div className={styles.formItemRow}>
          <div className={styles.label}>Location</div>
          <Form.Item name='location' rules={[{ required: false, message: 'Please input location!' }]} style={{ width: '100%' }}>
            <Input
              placeholder='Ex. Chicago, IL'
              size='large'
              style={{ width: '100%' }}
              prefix={<EnvironmentOutlined style={{ fontSize: 20, color: '#667085 ' }} />}
            />
          </Form.Item>
        </div>
        <div className={styles.formItemRow}>
          <div className={styles.label}>Store Description</div>
          <Form.Item name='description' rules={[{ required: true, message: 'Please input store description!' }]} style={{ width: '100%' }}>
            <QuillEditorAntd limit={400} />
          </Form.Item>
        </div>
        <div className={styles.formItemRow}>
          <div className={styles.label}>
            Cover Image
            <div className={styles.labelSubtitle}>
              Cover will be displayed on your profile{' '}
              <span className={styles.labelLink} onClick={() => setStoreCoverModalVisible(true)}>
                Learn how it works?
              </span>
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <Form.Item
              name='bannerImageUrl'
              rules={[{ required: false, message: 'Please add cover image!' }]}
              style={{ width: '100%' }}
              valuePropName='storeFileList'
            >
              <UploadImage aspectRatio={352 / 110} big />
            </Form.Item>
          </div>
        </div>
        <div className={styles.divider} />

        <div className={styles.formItemRow}>
          <div className={styles.label}>Social Links</div>
          <Form.Item
            name='facebookLink'
            rules={[{ required: false, message: 'Please input your facebook link!' }]}
            style={{ width: '100%' }}
          >
            <SocialItem socialNetwork={SocialNetwork.Facebook} onChange={(newValue) => (store.facebookLink = newValue)} />
          </Form.Item>
        </div>

        <div className={styles.formItemRow}>
          <Form.Item
            name='instagramLink'
            rules={[{ required: false, message: 'Please input your instagram link!' }]}
            style={{ width: '100%' }}
          >
            <SocialItem socialNetwork={SocialNetwork.Instagram} onChange={(newValue) => (store.instagramLink = newValue)} />
          </Form.Item>
        </div>

        <div className={styles.formItemRow}>
          <Form.Item name='tiktokLink' rules={[{ required: false, message: 'Please input your tiktok link!' }]} style={{ width: '100%' }}>
            <SocialItem socialNetwork={SocialNetwork.TikTok} onChange={(newValue) => (store.tiktokLink = newValue)} />
          </Form.Item>
        </div>

        <div className={styles.formItemRow}>
          <Form.Item name='twitterLink' rules={[{ required: false, message: 'Please input your twitter link!' }]} style={{ width: '100%' }}>
            <SocialItem socialNetwork={SocialNetwork.Twitter} onChange={(newValue) => (store.twitterLink = newValue)} />
          </Form.Item>
        </div>

        <div className={styles.formItemRow}>
          <Form.Item
            name='linkedInLink'
            rules={[{ required: false, message: 'Please input your linkedIn link!' }]}
            style={{ width: '100%' }}
          >
            <SocialItem socialNetwork={SocialNetwork.LinkedIn} />
          </Form.Item>
        </div>

        <div className={styles.formItemRow}>
          <Form.Item name='youtubeLink' rules={[{ required: false, message: 'Please input your youtube link!' }]} style={{ width: '100%' }}>
            <SocialItem socialNetwork={SocialNetwork.YouTube} />
          </Form.Item>
        </div>
        <div className={styles.formItemRow}>
          <Form.Item
            name='pinterestLink'
            rules={[{ required: false, message: 'Please input your pinterest link!' }]}
            style={{ width: '100%' }}
          >
            <SocialItem socialNetwork={SocialNetwork.Pinterest} />
          </Form.Item>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.btnGroup}>
          <Button size='large' onClick={handleReset}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' loading={store.status === 'loading'} size='large'>
            Save
          </Button>
        </div>
      </div>
      <StoreLogoModal isVisible={isStoreLogoModalVisible} onClose={() => setStoreLogoModalVisible(false)} />
      <StoreCoverModal isVisible={isStoreCoverModalVisible} onClose={() => setStoreCoverModalVisible(false)} />
    </Form>
  )
}

export default observer(StoreSettings)
